<template>
  <b-card class="card-congratulation-medal">
    <h5>Fatture in Cloud</h5>
    <div v-if="loading_fic === true" class="mt-3 ml-3">
      <b-spinner 
        variant="primary"
        style="width: 3rem; height: 3rem;"
        label="loading"
      />
    </div>
    <div v-else>
      <div v-if="api_token_errore_caricamento === false">
        <div v-if="api_token_fic_esito_controllo === false">
          <b-card border-variant="danger" style="margin: 20px 80px 0 0;">
            <b-card-text>Syncro Api disconnessa.</b-card-text>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              :href="url_fic_auth"
            >
              Riconnetti
            </b-button>
          </b-card>
        </div>
        <div v-else>
          <b-card-text class="font-small-3">
            Token Api attivo
          </b-card-text>
          <b-card-text class="font-small-3">
            valido fino al<br />
            {{ formattazioneDataOra(api_token_fic_scadenza, 'it') }}<br />
          </b-card-text>
          <h6 class="mb-75 mt-2 text-primary">
            Si auto-rinnoverà prima della scadenza
          </h6>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-warning"
            @click="forzaAggiornamentoTokenApi()"
          >
            Aggiorna token manualmente
          </b-button>
        </div>
      </div>
      <div v-else>
        <b-card border-variant="error">
          <b-card-text>Errore nel caricamento della configurazioni per la syncro con Fatture in Cloud. Prego avvisare la nostra assistenza</b-card-text>
        </b-card>
      </div>
    </div>

    <b-img
      :src="require('@/assets/images/logo-partners/fattureincloud.jpg')"
      class="congratulation-medal"
      style="right: 17px;"
      alt="Fatture in Cloud"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import moment from 'moment'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    api_token_fic_scadenza: '',
    api_token_fic_esito_controllo: false,
    url_fic_auth: '', 
    api_token_errore_caricamento: false,
    loading_fic: true,
  },
  methods: {
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('LLLL')
      } else {
        return '---'
      }
    },
    forzaAggiornamentoTokenApi(){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma il refresh manuale del TokenApi di Fatture in Cloud?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-warning',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.get('api/crm/fic/refresh_token')
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.messaggio,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //aggiorna data di scadenza visualizzata
                  this.$emit('update:api_token_fic_scadenza', response.data.reply.dati_config_token.token_expires_datatime)
                  
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: response.data.reply.message,
                      customClass: {
                      confirmButton: 'btn btn-outline-danger',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  }
}
</script>

<template>
  <section>
    <b-row class="match-height" v-if="block_check_token_api_fic">
      <b-col md="4">
        <b-card>
          <b-card-text>
            <h2 class="mb-0"><small class="color-primary">Ciao</small><br />{{ this.userData.name }}</h2>
            <p>Se un <strong>{{ this.userData.role }}</strong></p>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col md="4">
        <card-check-google
          :id_utente.sync = "id_utente_loggato"
          :api_token_google.sync = "api_token_google"
          :loading_google = "loading_google"
          :errore_caricamento.sync = "api_token_google_errore_caricamento"
        />
      </b-col>

      <b-col md="4">
        <card-check-fic
          :api_token_fic_scadenza.sync = "api_token_fic_scadenza"
          :loading_fic = "loading_fic"
          :api_token_fic_esito_controllo = "api_token_fic_esito_controllo"
          :url_fic_auth = "url_fic_auth"
          :api_token_errore_caricamento.sync = "api_token_errore_caricamento"
        />

      </b-col>
    </b-row>

    <b-row class="match-height" v-else>
      <b-col md="8">
        <b-card>
          <b-card-text>
            <h2 class="mb-0"><small class="color-primary">Ciao</small><br />{{ this.userData.name }}</h2>
            <p>Se un <strong>{{ this.userData.role }}</strong></p>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col md="4">
        <card-check-google
          :id_utente.sync = "id_utente_loggato"
          :api_token_google.sync = "api_token_google"
          :loading_google = "loading_google"
          :errore_caricamento.sync = "api_token_google_errore_caricamento"
        />
        
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="6"
        md="12"
      >
        <b-card
          title="Attività in corso"
          class="mb-2"
        >
          <b-card-text>
            ...
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xl="6"
        md="12"
      >
        <b-card
          title="Area Statistiche"
          class="mb-2"
        >
          <b-card-text>
            ...
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BImg, BAvatar } from 'bootstrap-vue'

import CardCheckFic from './CardCheckFic'
import CardCheckGoogle from './CardCheckGoogle'

export default {
  components: {
    BRow,
    BCol,
    BCard, 
    BCardText,
    BAvatar,
    BImg,

    CardCheckFic,
    CardCheckGoogle,
  },
  data() {
    return {
      userData: {},

      id_utente_loggato: '',
      api_token_google: [],
      loading_google: true,
      api_token_google_errore_caricamento: false,

      block_check_token_api_fic: false,
      api_token_fic_scadenza: '',
      api_token_fic_esito_controllo: false,
      url_fic_auth: '', 
      loading_fic: true,
      api_token_errore_caricamento: false,
    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)

    this.id_utente_loggato = this.userData.id

    this.checkTokenApiGoogle(this.id_utente_loggato)
    this.checkTokenApiFIC(this.userData.role)
  },
  methods: {
    checkTokenApiGoogle(id_employee){
      this.$http.get('api/crm/google/oauth/checktoken/'+id_employee).then(response => {
        if(response.data.statusCode === 200){
          //ok dati ricevuti
          this.api_token_google = response.data.reply.data;
          //console.log(response.data.reply.data)

          this.loading_google = false
          this.api_token_google_errore_caricamento =  false;
        } else {
          //errore da api
          this.loading_google = false
          this.api_token_google_errore_caricamento = true;
        }
      
      }).catch(e => {
        this.loading_google = false
        this.api_token_google_errore_caricamento = true;
      }); 
    },

    checkTokenApiFIC(ruolo_employeee){
      if(ruolo_employeee == 'SuperAdmin'){
        this.block_check_token_api_fic =  true;

        this.$http.get('api/crm/fic/check_token').then(response => {
          if(response.data.statusCode === 200){
            //ok dati ricevuti

            if(response.data.reply.dati_config_token === null){
              //mancano i dati di configurazione
              this.api_token_fic_esito_controllo = response.data.reply.esito_controllo;
              this.url_fic_auth = response.data.reply.url_fic_auth;

              console.log("FIC 01 -this.api_token_fic_esito_controllo -> "+this.api_token_fic_esito_controllo)
            } else {
              this.api_token_fic_scadenza = response.data.reply.dati_config_token.token_expires_datatime;
              //console.log("this.api_token_fic_scadenza -> "+this.api_token_fic_scadenza)

              this.api_token_fic_esito_controllo = response.data.reply.esito_controllo
              this.url_fic_auth = response.data.reply.url_fic_auth;

              console.log("FIC 02")
            }            

            this.loading_fic = false
            this.api_token_errore_caricamento =  false;
          } else {
            //errore da api
            this.loading_fic = false
            this.api_token_errore_caricamento = true;
          }
        
        }).catch(e => {
          this.api_token_errore_caricamento = true;
        });        

      } else {
        this.block_check_token_api_fic =  false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
